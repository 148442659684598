















































































































































































































































import useColor from "@/use/color";
import useEmissionStandard from "@/use/emissionStandard";
import useUser from "@/use/user";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    AVehicleIcon: () => import("@/components/atoms/a-vehicle-icon.vue"),

    OVehicleDetails: () =>
      import("@/components/organisms/o-vehicle-details.vue"),
    OVehicleService: () =>
      import("@/components/organisms/o-vehicle-service.vue"),
    OVehicleLeasing: () =>
      import("@/components/organisms/o-vehicle-leasing.vue"),
    OVehicleInsurance: () =>
      import("@/components/organisms/o-vehicle-insurance.vue"),
    OVehicleAttachment: () =>
      import("@/components/organisms/o-vehicle-attachment.vue"),
    OVehicleAlert: () => import("@/components/organisms/o-vehicle-alert.vue"),
    OVehicleReservation: () =>
      import("@/components/organisms/o-vehicle-reservation.vue"),
    OVehicleProtocols: () =>
      import("@/components/organisms/o-vehicle-protocols.vue"),
    OVehicleSettings: () =>
      import("@/components/organisms/o-vehicle-settings.vue"),

    MCompanyUserSelect: () =>
      import("@/components/molecules/m-company-user-select.vue"),

    OAddComment: () => import("@/components/organisms/o-add-comment.vue"),
    OEditComment: () => import("@/components/organisms/o-edit-comment.vue"),
    ODeleteComment: () => import("@/components/organisms/o-delete-comment.vue"),

    OVehicleMileage: () =>
      import("@/components/organisms/o-vehicle-mileage.vue"),

    OVehicleDamages: () =>
      import("@/components/organisms/o-vehicle-damages.vue"),
  },

  setup(props, { root }) {
    const apiInstance = root?.$store.getters[
      "api/getInstance"
    ] as AxiosInstance;
    const vehicle = ref<null | { [key: string]: any }>(null);

    const { emissionStandards } = useEmissionStandard();
    const { isDark } = useColor();

    const driverForm = ref<any>(null);
    const mileageForm = ref<any>(null);

    const { hasAccessTo } = useUser({ root });

    const state = reactive({
      error: false,
      loading: false,

      driverLoading: false,
      driverDialog: false,
      isDriverFormValid: true,
    });

    const model = reactive({
      driver: { user: null as any },
    });

    const rules = {
      driver: [(v: any) => !!v || "Wybierz użytkownika"],
      mileage: [(v: any) => !!v || "Podaj przebieg"],
    };

    const fetch = () => {
      state.loading = true;

      apiInstance
        .get(`vehicle/${root.$route.params.id}`)
        .then(({ data }) => (vehicle.value = data.vehicle))
        .catch(() => (state.error = true))
        .finally(() => (state.loading = false));
    };

    const addDriver = () => {
      state.driverLoading = true;

      apiInstance
        .put(`vehicle/${root.$route.params.id}/driver`, {
          driverId: model.driver.user?.id,
        })
        .then(fetch)
        .catch((error) => console.log(error))
        .finally(() => (state.driverLoading = false));
    };

    const removeDriver = () => {
      state.driverLoading = true;

      apiInstance
        .delete(`vehicle/${root.$route.params.id}/driver`)
        .then(fetch)
        .catch((error) => console.log(error))
        .finally(() => {
          state.driverDialog = false;
          state.driverLoading = false;
        });
    };

    const onDriverSubmit = async () => {
      await driverForm.value.validate();
      if (state.isDriverFormValid) addDriver();
    };

    onMounted(fetch);

    const currentDamages = computed(() =>
      vehicle.value?.damages?.filter(
        (damage: any) => damage.status === "in_progress"
      )
    );

    return {
      emissionStandards,
      driverForm,
      mileageForm,
      vehicle,
      state,
      model,
      rules,
      onDriverSubmit,
      removeDriver,
      hasAccessTo,
      fetch,
      isDark,
      encodeURIComponent,
      currentDamages,
    };
  },
});
